import * as Sentry from '@sentry/react';

import LoginView, { LoginViewTabEnum } from './pages/login/LoginView.js';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { useEffect, useMemo } from 'react';

import DefaultWorkspaceReroute from './pages/home/DefaultWorkspaceReroute.js';
import Home from './pages/home/Home.js';
import IframeContainer from './pages/iframe/Container.js';
import LoginContainer from './pages/login/LoginContainer.js';
import OauthPage from './pages/oauth/OauthPage.js';
import PasswordReset from './pages/login/PasswordReset.js';
import { ROUTES } from 'common-ts';
import TwoFactorSelect from './pages/login/TwoFactorSelect.js';
import TwoFactorVerificationForm from './pages/login/TwoFactorVerificationForm.js';
import { captureConsoleIntegration } from '@sentry/integrations';
import posthog from 'posthog-js';
import { useBoundStore } from './store/useBoundStore.js';
import useForceUpdateHook from './general/useForceUpdateHook.js';
import InviteLinkeReroute from './pages/home/InviteLinkeReroute.js';

function initPosthog() {
  // This can also be done by configuring our posthog account to filter out
  // events from localhost and certain domains.
  // However, this is the quickest way to get it working. We can always
  // change it later.

  const stage = import.meta.env.VITE_MAIA_STAGE;
  if (stage === 'prod') {
    posthog.init('phc_DBLAnNf6nbOhRG1E18VY2Nca2oWNxvMD86t4YunRSps', {
      api_host: 'https://eu.posthog.com',
    });
  }
}

function initSentry() {
  const stage = import.meta.env.VITE_MAIA_STAGE;
  const sentryDsn = import.meta.env.VITE_MAIA_APP_SENTRY_DSN;
  if (stage === 'prod' || stage === 'dev') {
    let target: (string | RegExp)[] = [];
    if (stage === 'prod') {
      target = [
        /^https:\/\/api\.getmaia\.io\//,
        /^https:\/\/sb\.getmaia\.io\//,
      ];
    } else if (stage === 'dev') {
      target = [
        /^https:\/\/api\.maia\.prodlane\.de\//,
        /^https:\/\/sb\.maia\.prodlane\.de\//,
      ];
    }

    Sentry.init({
      dsn: sentryDsn,
      environment: stage,
      // This enables automatic instrumentation (highly recommended),
      // but is not necessary for purely manual usage
      // If you only want to use custom instrumentation:
      // * Remove the `BrowserTracing` integration
      // * add `Sentry.addTracingExtensions()` above your Sentry.init() call
      integrations: [
        // eslint-disable-next-line import/namespace
        Sentry.browserTracingIntegration(),
        captureConsoleIntegration(),
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: target,
    });
  }
}

function App() {
  const supabase = useBoundStore((state) => state.supabase);
  useForceUpdateHook();

  useEffect(() => {
    initPosthog();
    initSentry();
  }, []);

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: ROUTES.REGISTER.path,
          element: <Navigate to={ROUTES.AUTH.SIGN_IN.path} replace />,
        },
        {
          path: ROUTES.AUTH.path,
          element: <LoginContainer />,
          children: [
            {
              path: ROUTES.AUTH.$.SIGN_IN.relativePath,
              element: <LoginView />,
              index: true,
            },
            {
              path: ROUTES.AUTH.$.SIGN_UP.relativePath,
              element: <LoginView defaultTab={LoginViewTabEnum.REGISTER} />,
            },
            {
              path: ROUTES.AUTH.$.RESET_PASSWORD.relativePath,
              element: <PasswordReset />,
            },
            {
              path: ROUTES.AUTH.$.TWO_FACTOR_SELECT.relativePath,
              element: <TwoFactorSelect />,
            },
            {
              path: ROUTES.AUTH.$.TWO_FACTOR.relativePath,
              element: <TwoFactorVerificationForm />,
            },
          ],
        },
        {
          path: ROUTES.INVITED.path,
          element: (
            <DefaultWorkspaceReroute
              redirectTo={(workspaceId) => {
                return ROUTES.HOME.SETTINGS.ACCOUNT.PERSONAL.buildPath(
                  { workspaceId },
                  { setPassword: 'true' }
                );
              }}
            />
          ),
        },
        {
          path: ROUTES.RESETTING_PW.path,
          element: (
            <DefaultWorkspaceReroute
              redirectTo={(workspaceId) => {
                return ROUTES.HOME.SETTINGS.ACCOUNT.PERSONAL.buildPath(
                  { workspaceId },
                  { resetPassword: 'true' }
                );
              }}
            />
          ),
        },
        {
          path: ROUTES.OAUTH.path, // Route outside of HOME (/:workspaceId) so that we have a static route to redirect to from the Oauth flow
          element: <OauthPage />,
        },
        {
          path: ROUTES.HOME.path,
          element: <Home />,
        },
        {
          path: ROUTES.INVITE_LINK_WITH_CODE.path,
          element: <InviteLinkeReroute />,
        },
        {
          path: ROUTES.IFRAME.path,
          element: <IframeContainer />,
        },
        { path: '*', element: <DefaultWorkspaceReroute /> },
      ]),
    [supabase]
  );
  return <RouterProvider router={router} />;
}

export default App;
