import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';

type SharepointBreadCrumbsProps = {
  className?: string;
  folders: { id: string; name: string }[];
  onFolderSelect: (index: number) => void;
};

function SharepointBreadCrumbs({
  className,
  folders,
  onFolderSelect,
}: SharepointBreadCrumbsProps) {
  return (
    <Breadcrumb separator={'>'} className={className}>
      {folders.map((folder, index) => {
        return (
          <BreadcrumbItem key={folder.id}>
            <BreadcrumbLink as={'div'} onClick={() => onFolderSelect(index)}>
              {folder.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
}

export default SharepointBreadCrumbs;
