import {
  Badge,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import AccessControl from '../../components/AccessControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SharepointSiteStatus } from 'common-ts';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

type SharepointSiteStatusTableProps = {
  sites: SharepointSiteStatus[];
  onDisconnectClick: (sharepointSiteId: string) => void;
};

function SharepointSiteStatusTable({
  sites,
  onDisconnectClick,
}: SharepointSiteStatusTableProps) {
  const { t } = useTranslation();

  const statusTranslationMap: {
    [key in SharepointSiteStatus['status']]: string;
  } = {
    PENDING: t(
      'integrationSettings.sharepoint.connectedSitesTable.statusBadge.pending'
    ),
    'IN PROGRESS': t(
      'integrationSettings.sharepoint.connectedSitesTable.statusBadge.inProgress'
    ),
    SYNCED: t(
      'integrationSettings.sharepoint.connectedSitesTable.statusBadge.synced'
    ),
    FAILED: t(
      'integrationSettings.sharepoint.connectedSitesTable.statusBadge.failed'
    ),
  };

  return (
    <TableContainer className="border-maia-border rounded-xl border">
      <Table variant="simple">
        <Thead>
          <Tr className="font-semibold">
            <Th>
              {t('integrationSettings.sharepoint.connectedSitesTable.site')}
            </Th>
            <Th>
              {t(
                'integrationSettings.sharepoint.connectedSitesTable.syncedFiles'
              )}
            </Th>
            <Th>
              {t(
                'integrationSettings.sharepoint.connectedSitesTable.totalFiles'
              )}
            </Th>
            <Th>
              {t(
                'integrationSettings.sharepoint.connectedSitesTable.usedStorage'
              )}
            </Th>
            <Th>
              {t('integrationSettings.sharepoint.connectedSitesTable.status')}
            </Th>
            <AccessControl allowedRoles={['ADMIN', 'OWNER']}>
              <Th />
            </AccessControl>
          </Tr>
        </Thead>
        <Tbody className="text-maia-text-dark ">
          {sites.map((site) => {
            return (
              <Tr key={site.id}>
                <Td>{site.name}</Td>
                <Td>{site.status === 'PENDING' ? '?' : site.syncedFiles}</Td>
                <Td>{site.status === 'PENDING' ? '?' : site.totalFiles}</Td>
                <Td>{site.usedStorage.toFixed(2)}MB</Td>
                <Td>
                  <Badge
                    size={'xs'}
                    className={`${
                      site.status === 'IN PROGRESS'
                        ? 'bg-maia-support-yellow'
                        : site.status === 'FAILED'
                          ? 'bg-maia-support-red'
                          : site.status === 'PENDING'
                            ? 'bg-chakra-gray-400'
                            : 'bg-maia-support-green'
                    } text-xs text-white`}
                  >
                    {statusTranslationMap[site.status]}
                  </Badge>
                </Td>

                <AccessControl allowedRoles={['ADMIN', 'OWNER']}>
                  <Td>
                    <Menu>
                      <MenuButton
                        size={'sm'}
                        as={IconButton}
                        variant={'icon-only'}
                        icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                        aria-label="Sharepoint site settings"
                      />
                      <MenuList className="min-w-[130px]">
                        <MenuItem
                          onClick={() => {
                            onDisconnectClick(site.siteId);
                          }}
                        >
                          {t(
                            'integrationSettings.sharepoint.connectedSitesTable.disconnect'
                          )}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </AccessControl>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default SharepointSiteStatusTable;
