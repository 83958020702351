import { AvailableModelEnum, MaiaApiRoutes } from 'common-ts';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';

import BasicSearch from '../search/BasicSearch.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchApi } from '../../utils/useApi.js';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useTranslation } from 'react-i18next';

export const SEARCH_ROUTE_SEARCH_ANSWER_ID = 'searchAnswerId';

type Collection = {
  id: string;
  name: string;
  workspaceId: string;
};

type SearchProps = {
  iframeId: string;
  iFrameSettings: MaiaApiRoutes['/iframe']['/:iframe_id']['response'];
};

type AvailableModels = ('GPT4o mini' | 'GPT4o')[];

export default function Search({ iframeId, iFrameSettings }: SearchProps) {
  const supabase = useBoundStore((state) => state.supabase);
  const { showToast } = useToastManagerHook();
  const { t } = useTranslation();

  const [selectedModel, setSelectedModel] = useState<
    (typeof availableModels)[number] | undefined
  >(
    iFrameSettings.fixModel
      ? iFrameSettings.fixModel === AvailableModelEnum.GPT_4_O_MINI
        ? 'GPT4o mini'
        : 'GPT4o'
      : undefined
  );
  const [availableCollections, setAvailableCollections] = useState<
    Collection[]
  >([]);
  const [availableModels, setAvailableModels] = useState<AvailableModels>([]);
  const [rateLimitInfo, setRateLimitInfo] = useState<{
    gpt35Requests: number;
    gpt4Requests: number;
    gpt35Limit: number;
    gpt4Limit: number;
  }>({
    gpt35Requests: 0,
    gpt4Requests: 0,
    gpt35Limit: 0,
    gpt4Limit: 0,
  });

  async function fetchCollections() {
    const endpoint =
      `/${iframeId}/available_collections` as '/:iframe_id/available_collections';

    const response = await fetchApi(
      supabase,
      '/iframe',
      endpoint,
      {
        method: 'GET',
        params: { iframe_id: iframeId },
      },
      true
    );

    if (response.success && response.data.length > 0) {
      setAvailableCollections(response.data);
    }
  }

  const handleRateLimit = () => {
    switch (selectedModel) {
      case 'GPT4o mini':
        if (rateLimitInfo.gpt35Requests >= rateLimitInfo.gpt35Limit) {
          showToast({
            title: t('iframe.limitReachedError', {
              modelName: 'GPT4o mini',
            }),
            status: 'error',
          });
          return true;
        }
        break;
      case 'GPT4o':
        if (rateLimitInfo.gpt4Requests >= rateLimitInfo.gpt4Limit) {
          showToast({
            title: t('iframe.limitReachedError', {
              modelName: 'GPT-4o',
            }),
            status: 'error',
          });
          return true;
        }
        break;
      default: // Do nothing
        break;
    }
    return false;
  };

  const fetchRateLimits = async () => {
    const endpoint = `/${iframeId}/rate_limit` as '/:iframe_id/rate_limit';
    const response = await fetchApi(
      supabase,
      '/iframe',
      endpoint,
      {
        method: 'GET',
        params: { iframe_id: iframeId },
      },
      true
    );
    if (!response.success) return;

    const { gpt35Requests, gpt4Requests, gpt35Limit, gpt4Limit } =
      response.data;
    setRateLimitInfo({
      gpt35Requests,
      gpt4Requests,
      gpt35Limit,
      gpt4Limit,
    });
  };

  const getLlmModel = () => {
    switch (selectedModel) {
      case 'GPT4o mini':
        return AvailableModelEnum.GPT_4_O_MINI;
      case 'GPT4o':
        return AvailableModelEnum.GPT_4_O;
      default:
        return AvailableModelEnum.GPT_4_O_MINI;
    }
  };

  useEffect(() => {
    fetchCollections();
    fetchRateLimits();
  }, []);

  useEffect(() => {
    const tempModels: AvailableModels = [];
    if (rateLimitInfo.gpt4Limit) {
      tempModels.push('GPT4o');
    }
    if (rateLimitInfo.gpt35Limit) {
      tempModels.push('GPT4o mini');
    }

    !selectedModel && setSelectedModel(tempModels[0]);
    setAvailableModels(tempModels);
  }, [rateLimitInfo]);

  const modelSelectionJSX = () =>
    !iFrameSettings.fixModel ? (
      <Menu gutter={4} matchWidth>
        {({ isOpen }) => (
          <>
            <MenuButton className="border-maia-border hover:border-maia-accent w-30 rounded-md border px-3 py-1.5 text-left lg:max-w-xs">
              <div className="flex items-center justify-between space-x-2">
                <span>{selectedModel}</span>
                {isOpen ? (
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    className="text-maia-accent"
                  />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )}
              </div>
            </MenuButton>
            <MenuList>
              {availableModels.map((model, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setSelectedModel(model);
                  }}
                >
                  {model}
                </MenuItem>
              ))}
            </MenuList>
          </>
        )}
      </Menu>
    ) : null;

  return (
    <div
      className={`flex min-h-screen w-full flex-col items-center gap-y-6 bg-neutral-50 pb-0 pt-0 md:pt-24`}
    >
      <BasicSearch
        iframeId={iframeId}
        collections={availableCollections}
        llmModel={getLlmModel()}
        sentRequests={
          selectedModel === 'GPT4o mini'
            ? rateLimitInfo.gpt35Requests
            : rateLimitInfo.gpt4Requests
        }
        maxRequests={
          selectedModel === 'GPT4o mini'
            ? rateLimitInfo.gpt35Limit
            : rateLimitInfo.gpt4Limit
        }
        ModelSelectionJSX={modelSelectionJSX()}
        fetchRateLimitInfo={fetchRateLimits}
        handleRateLimit={handleRateLimit}
        iFrameSettings={iFrameSettings}
        powerModeAccess={iFrameSettings.featPowerMode}
      />
    </div>
  );
}
