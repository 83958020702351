import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

type ConfirmDeletionModalProps = {
  title: string;
  subtitle: string;
  isOpen: boolean;
  saveWord?: string;
  deleteButtonLabel?: string;
  onClose: () => void;
  onConfirm: (saveWord?: string) => void;
};

export default function DeleteModal({
  title,
  subtitle,
  isOpen,
  saveWord,
  deleteButtonLabel,
  onConfirm,
  onClose,
}: ConfirmDeletionModalProps) {
  const { t } = useTranslation();
  const cancelRef = useRef(null);

  const [typedSaveWord, setTypedSaveWord] = useState<string>('');
  const [deleteDisabled, setDeleteDisabled] = useState(!!saveWord);

  function handleSaveWord(value: string) {
    setTypedSaveWord(value);
    if (value === saveWord) {
      setDeleteDisabled(false);
    } else {
      !deleteDisabled && setDeleteDisabled(!deleteDisabled);
    }
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          <AlertDialogBody>
            <div>{subtitle}</div>
            {saveWord && (
              <FormControl>
                <FormLabel htmlFor="password">
                  {t('general.saveWordInstruction', {
                    saveWord,
                  })}
                </FormLabel>
                <Input
                  type="email"
                  backgroundColor={'white'}
                  placeholder={t('userSettings.email')}
                  defaultValue={typedSaveWord}
                  onChange={(e) => {
                    // validation
                    handleSaveWord(e.target.value);
                  }}
                />
              </FormControl>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} variant="outline">
              {t('general.cancelButton')}
            </Button>
            <Button
              isDisabled={deleteDisabled}
              colorScheme="red"
              onClick={() => onConfirm(typedSaveWord)}
              ml={3}
            >
              {deleteButtonLabel ?? t('general.deleteButton')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
