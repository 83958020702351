import { Checkbox, IconButton, Skeleton, Tooltip } from '@chakra-ui/react';
import { Database, decodeName } from 'common-ts';

import FileIcon from '../../../components/FileIcon.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { zeros } from 'common-ts';

export type FileWithStatus = {
  embeddingStatus: Database['public']['Enums']['embedding_status'];
  fileInfoId: string;
  name: string;
};

type SelectableCollectionFileListProps = {
  className?: string;
  files: FileWithStatus[] | undefined;
  searchTerm: string;
  selectedFiles: Map<string, string> | undefined; // Map with fileInfoId - fileName
  onBackClick: () => void;
  onFileSelect: (files: { fileInfoId: string; name: string }[]) => void;
  onFileUnselect: (files: { fileInfoId: string; name: string }[]) => void;
  onRefreshFilesClick?: () => void;
};

function SelectableCollectionFileList({
  className,
  files,
  searchTerm,
  selectedFiles,
  onBackClick,
  onFileSelect,
  onFileUnselect,
  onRefreshFilesClick,
}: SelectableCollectionFileListProps) {
  const { t } = useTranslation();

  const matchingFiles: FileWithStatus[] = [];
  const remainingFiles: FileWithStatus[] = [];
  files?.forEach((file) => {
    if (
      searchTerm &&
      decodeName(file.name).toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      matchingFiles.push(file);
    } else {
      remainingFiles.push(file);
    }
  });

  return (
    <div className={twMerge('flex h-full flex-col gap-2 px-4 py-2', className)}>
      <div className="flex items-center justify-between">
        <div className="flex items-baseline gap-2">
          <IconButton
            className="self-start md:hidden"
            icon={<FontAwesomeIcon icon={faArrowLeft} className="text-xs" />}
            aria-label="back"
            variant={'icon-only'}
            size="xs"
            onClick={onBackClick}
          />
          <div className="text-chakra-gray-500">{`${files?.length} ${t(
            'fileManagerPanel.totalFiles'
          )}`}</div>
          <IconButton
            aria-label="Refresh file list"
            variant={'ghost'}
            size={'xs'}
            icon={
              <FontAwesomeIcon
                icon={faRotateRight}
                className="text-chakra-gray-600 text-xs"
              />
            }
            onClick={onRefreshFilesClick}
          />
        </div>
        {files?.length ? (
          <div className="flex items-center gap-2">
            <div
              className="text-maia-accent cursor-pointer select-none text-xs"
              onClick={() => onFileSelect(files ?? [])}
            >
              {t('general.selectAll')}
            </div>
            <div
              className="text-maia-support-red cursor-pointer select-none text-xs"
              onClick={() => onFileUnselect(files ?? [])}
            >
              {t('general.deselectAll')}
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex flex-col overflow-y-auto">
        {files ? (
          <>
            {matchingFiles.map((file) => {
              const decodedName = decodeName(file.name);
              const searchTermIndex = decodedName
                .toLowerCase()
                .indexOf(searchTerm.toLowerCase());
              const prefix = decodedName.slice(0, searchTermIndex);
              const boldPart = decodedName.slice(
                searchTermIndex,
                searchTermIndex + searchTerm.length
              );
              const suffix = decodedName.slice(
                searchTermIndex + searchTerm.length
              );

              return (
                <Checkbox
                  isDisabled={file.embeddingStatus !== 'FINISHED'}
                  key={file.fileInfoId}
                  colorScheme="maia-purple"
                  className="hover:bg-maia-purple-50 rounded px-4"
                  isChecked={!!selectedFiles?.get(file.fileInfoId)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      onFileSelect([
                        { fileInfoId: file.fileInfoId, name: file.name },
                      ]);
                    } else {
                      onFileUnselect([
                        { fileInfoId: file.fileInfoId, name: file.name },
                      ]);
                    }
                  }}
                >
                  <Tooltip
                    isDisabled={file.embeddingStatus === 'FINISHED'}
                    label={
                      file.embeddingStatus === 'FAILED'
                        ? t('chat.fileSelector.fileFailedTooltip')
                        : t('chat.fileSelector.fileNotProcessedTooltip')
                    }
                  >
                    <div className="flex h-10 w-72 items-center gap-2 pl-2">
                      <FileIcon
                        name={file.name}
                        status={file.embeddingStatus}
                        className="text-chakra-gray-400 text-base"
                      />
                      <Tooltip label={decodedName} openDelay={150}>
                        <span className="truncate text-sm">
                          <span>{prefix}</span>
                          <span className="font-semibold ">{boldPart}</span>
                          <span>{suffix}</span>
                        </span>
                      </Tooltip>
                    </div>
                  </Tooltip>
                </Checkbox>
              );
            })}
            {remainingFiles.map((file) => {
              const decodedName = decodeName(file.name);

              return (
                <Checkbox
                  isDisabled={file.embeddingStatus !== 'FINISHED'}
                  key={file.fileInfoId}
                  colorScheme="maia-purple"
                  className="hover:bg-maia-purple-50 rounded px-4"
                  isChecked={!!selectedFiles?.get(file.fileInfoId)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      onFileSelect([
                        { fileInfoId: file.fileInfoId, name: file.name },
                      ]);
                    } else {
                      onFileUnselect([
                        { fileInfoId: file.fileInfoId, name: file.name },
                      ]);
                    }
                  }}
                >
                  <Tooltip
                    isDisabled={file.embeddingStatus === 'FINISHED'}
                    label={
                      file.embeddingStatus === 'FAILED'
                        ? t('chat.fileSelector.fileFailedTooltip')
                        : t('chat.fileSelector.fileNotProcessedTooltip')
                    }
                  >
                    <div className="flex h-10 w-72 items-center pl-2">
                      <FileIcon
                        name={file.name}
                        status={file.embeddingStatus}
                        className="text-chakra-gray-400 mr-2 text-base"
                      />
                      <Tooltip label={decodedName} openDelay={150}>
                        <span className="truncate text-sm">
                          {decodedName.slice(0, -10)}
                        </span>
                      </Tooltip>
                      <Tooltip label={decodedName} openDelay={150}>
                        <span className="text-sm">
                          {decodedName.slice(-10)}
                        </span>
                      </Tooltip>
                    </div>
                  </Tooltip>
                </Checkbox>
              );
            })}
          </>
        ) : (
          zeros(3).map((_, index) => (
            <Skeleton key={index} height={'10'} marginTop={'1'} />
          ))
        )}
      </div>
    </div>
  );
}

export default SelectableCollectionFileList;
