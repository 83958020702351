import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { fetchApi } from '../../utils/useApi';
import { useBoundStore } from '../../store/useBoundStore';
import { useState } from 'react';
import { useToastManagerHook } from '../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';

type FilePreviewIconButtonProps = {
  /**
   * Leftover of search result v1 (storage files only)
   */
  fileId?: string;
  documentId?: string;
  fileInfoId?: string;
  fileName: string;
  startingPage?: number;
};

function FilePreviewIconButton({
  fileId,
  documentId,
  fileInfoId,
  fileName,
  startingPage,
}: FilePreviewIconButtonProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const { showToast } = useToastManagerHook();
  const [urlLoading, setUrlLoading] = useState(false);

  async function handlePresignedUrl(
    fileId?: string,
    documentId?: string,
    fileInfoId?: string
  ) {
    setUrlLoading(true);

    let resolvedFileInfoId = fileInfoId;
    if (!resolvedFileInfoId) {
      const { data: fIdata, error: fIerror } = fileId
        ? await supabase
            .from('storage_file_view')
            .select('file_info_id')
            .eq('id', fileId)
            .limit(1)
        : documentId
          ? await supabase
              .from('file_info_view')
              .select('file_info_id')
              .eq('document_id', documentId)
              .limit(1)
          : { error: 'no fileId nor documentId provided', data: null };

      resolvedFileInfoId = (fIdata?.[0] && fIdata[0].file_info_id) ?? undefined;

      if (fIerror) {
        captureException(fIerror);
        showToast({ title: t('general.reloadError'), status: 'error' });
        setUrlLoading(false);
        return;
      }
    }

    if (!resolvedFileInfoId) {
      showToast({ title: t('general.resourceNotFound'), status: 'warning' });
      setUrlLoading(false);
      return;
    }

    const urlRes = await fetchApi(supabase, '/files', '/preview_url', {
      method: 'POST',
      fileInfoId: resolvedFileInfoId,
      workspaceId,
      startingPage,
    });

    if (!urlRes.success) {
      if (urlRes.data?.errorKey === 101) {
        showToast({ title: t('general.microsoftError'), status: 'error' });
      } else {
        showToast({ title: t('general.reloadError'), status: 'error' });
      }
      setUrlLoading(false);
      return;
    }
    setUrlLoading(false);
    window.open(urlRes.data.previewUrl, '_blank');
  }

  return (
    <IconButton
      isLoading={urlLoading}
      icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
      aria-label={`open document ${fileName} in another tab`}
      variant={'ghost'}
      className="text-maia-text-dark font-medium"
      onClick={(e) => {
        handlePresignedUrl(fileId, documentId, fileInfoId);
        e.stopPropagation();
      }}
    />
  );
}

export default FilePreviewIconButton;
