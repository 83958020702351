import { Progress, Text, Tooltip } from '@chakra-ui/react';

import AccessControl from '../../components/AccessControl';
import { t } from 'i18next';

type StorageCapacityProps = {
  usedMb: number;
  totalMb: number;
};

export default function StorageCapacity({
  usedMb,
  totalMb,
}: StorageCapacityProps) {
  const percentage = (usedMb / totalMb) * 100;
  const formattedUsed =
    usedMb < 1024
      ? `${usedMb.toFixed(2)} MB`
      : `${(usedMb / 1024).toFixed(2)} GB`;
  const formattedTotal =
    totalMb < 1024
      ? `${totalMb.toFixed(2)} MB`
      : `${(totalMb / 1024).toFixed(2)} GB`;

  const color = percentage > 90 ? 'orange' : 'blue';

  return (
    <div className="mx-auto my-0 flex flex-col items-center">
      <Progress
        role="progressbar"
        colorScheme={color}
        value={percentage}
        size={'xs'}
        width={'320px'}
      />
      <Text
        className="text-maia-text-dark mt-1"
        fontSize="xs"
        fontWeight="normal"
      >
        {`${formattedUsed} / ${formattedTotal} ${t('fileManagerPanel.used')}`}
      </Text>
      <AccessControl allowedLicenses={['ENTERPRISE', 'PROFESSIONAL']}>
        <Tooltip label={t('fileManagerPanel.workspaceStorageTooltip')}>
          <Text className="cursor-help text-xs">
            ({t('fileManagerPanel.wholeWorkspace')})
          </Text>
        </Tooltip>
      </AccessControl>
    </div>
  );
}
