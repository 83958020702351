import { Button, Skeleton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { captureException } from '@sentry/react';
import { fetchApi } from '../../utils/useApi';
import { twMerge } from 'tailwind-merge';
import { useBoundStore } from '../../store/useBoundStore';
import { useToastManagerHook } from '../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';

type SharepointWaitlistInfoProps = {
  className?: string;
};

function SharepointWaitlistInfo({ className }: SharepointWaitlistInfoProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const { showToast } = useToastManagerHook();

  const [loading, setLoading] = useState(false);
  const [isInWaitlist, setIsInWaitlist] = useState(false);

  async function fetchWaitlistInfo() {
    setLoading(true);
    const { data, error } = await supabase
      .from('sharepoint_waitlist_view')
      .select('*');

    if (error) {
      showToast({ title: t('general.tryAgainError'), status: 'error' });
      captureException(error);
      setLoading(false);
      return;
    }

    setLoading(false);
    setIsInWaitlist(!!data[0]?.joined_at);
  }

  useEffect(() => {
    fetchWaitlistInfo();
  }, []);

  async function handleJoinWaitList() {
    const res = await fetchApi(
      supabase,
      '/integrations',
      '/sharepoint/join_waitlist',
      { method: 'POST', workspaceId }
    );

    if (!res.success) {
      showToast({ title: t('general.tryAgainError'), status: 'error' });
      return;
    }

    fetchWaitlistInfo();
  }

  return (
    <div
      className={twMerge(
        'flex h-full w-full flex-col items-center justify-center gap-2 text-center',
        className
      )}
    >
      {loading ? (
        <Skeleton className="h-6" />
      ) : isInWaitlist ? (
        <div className="mb-2 w-3/4">
          {t('integrationSettings.sharepoint.waitList.userInWaitlistInfo')}
        </div>
      ) : (
        <>
          <div className="mb-2 w-3/4">
            {t('integrationSettings.sharepoint.waitList.userNotInWaitlistInfo')}
          </div>
          <Button colorScheme="maia-accent" onClick={handleJoinWaitList}>
            {t('integrationSettings.sharepoint.waitList.joinWaitlistButton')}
          </Button>
        </>
      )}
    </div>
  );
}

export default SharepointWaitlistInfo;
