import { getLlmModel, setLlmModel } from '../utils/localStorage.js';
import { useEffect, useState } from 'react';

import { AvailableModelEnum, ExperimentalModelEnum } from 'common-ts';
import { Select } from '@chakra-ui/react';
import { useBoundStore } from '../store/useBoundStore.js';

const supportedModelsMap = new Map<
  AvailableModelEnum | ExperimentalModelEnum,
  string
>([
  // keeping the naming convention consistent with ChatGpt
  [AvailableModelEnum.GPT_4_O, 'GPT-4o'],
  [AvailableModelEnum.GPT_4_O_MINI, 'GPT-4o mini'],
  [ExperimentalModelEnum.MISTRAL_LARGE_2407, 'Mistral-large'],
  [ExperimentalModelEnum.META_LLAMA_3_1_70B_INSTRUCT, 'Llama 3.1'],
]);

const DEFAULT_MODEL = AvailableModelEnum.GPT_4_O;

type ChangeLlmProps = {
  withExperimentalLlm?: boolean;
  onChange?: () => void;
};

export function ChangeLlm({
  onChange,
  withExperimentalLlm = false,
}: ChangeLlmProps) {
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );

  const [model, setModel] = useState<
    AvailableModelEnum | ExperimentalModelEnum
  >(getLlmModel(workspaceLicenseType ?? undefined) ?? DEFAULT_MODEL);

  const [availableModels, setAvailableModels] = useState<string[]>(
    Object.values(AvailableModelEnum).map((key) => supportedModelsMap.get(key)!)
  );

  const updateAvailableModels = () => {
    if (import.meta.env.VITE_MAIA_STAGE !== 'prod' && withExperimentalLlm) {
      const experimentalModels = Object.values(ExperimentalModelEnum);
      setAvailableModels((prevModels) => [
        ...prevModels,
        ...experimentalModels.map(
          (modelEnum) => supportedModelsMap.get(modelEnum)!
        ),
      ]);
    }
  };

  useEffect(() => {
    updateAvailableModels();
  }, []);

  function persistGpt(model: AvailableModelEnum | ExperimentalModelEnum) {
    setLlmModel(model);
    setModel(model);
    onChange?.();
  }

  return (
    <>
      <Select
        maxWidth="fit-content"
        variant="unstyled"
        className="text-maia-gray-900 text-sm font-normal"
        onChange={(e) => {
          persistGpt(
            e.target.value as ExperimentalModelEnum | AvailableModelEnum
          );
        }}
        value={model}
      >
        {Array.from(supportedModelsMap.entries())
          .filter(([_, modelName]) => availableModels.includes(modelName))
          .map(([modelEnum, modelName]) => (
            <option key={modelEnum} value={modelEnum}>
              {modelName}
            </option>
          ))}
      </Select>
    </>
  );
}
