import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

export type FileSelectorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  fileSelector: JSX.Element;
};

export const FileSelectorModal = ({
  isOpen,
  onClose,
  fileSelector,
}: FileSelectorModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxWidth="1236px" className="h-full max-md:m-0 md:h-auto">
        <ModalBody padding={0}>{fileSelector}</ModalBody>
        <ModalFooter gap={3}>
          <Button className="font-medium" variant="outline" onClick={onClose}>
            {t('general.doneButtons')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
