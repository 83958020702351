import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import {
  faMagnifyingGlass,
  faXmarkCircle,
} from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

type SearchBarProps = {
  className?: string;
  initialSearchTerm?: string;
  onSearchTermChange: (searchTerm: string) => void;
};

function SearchBar({
  className,
  initialSearchTerm = '',
  onSearchTermChange,
}: SearchBarProps) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    onSearchTermChange?.(newValue);
  };

  const clearSearchTerm = () => {
    setSearchTerm('');
    onSearchTermChange?.('');
    inputRef.current?.focus();
  };

  return (
    <InputGroup
      size="sm"
      className={twMerge('w-full max-w-56 pr-0.5', className)}
    >
      <InputLeftElement pointerEvents="none">
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="text-maia-accent"
        />
      </InputLeftElement>
      <Input
        ref={inputRef}
        placeholder={t('chat.fileSelector.searchPlaceholder')}
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
      {searchTerm && (
        <InputRightElement>
          <FontAwesomeIcon
            className="text-maia-text-dark cursor-pointer"
            icon={faXmarkCircle}
            size="sm"
            onClick={clearSearchTerm}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
}

export default SearchBar;
