import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from '@chakra-ui/react';
import { Database, decodeName, planStorageLimitsMb } from 'common-ts';
import { FileObjectWithStrategy, TempFileObject } from './FileRow.js';
import { ResponseResult, fetchApi } from '../../utils/useApi.js';
import { useEffect, useState } from 'react';

import ContentHeader from '../../components/ContentHeader.js';
import DeleteShareModal from './modals/DeleteShareModal';
import EditCollectionModal from './modals/EditCollectionModal.js';
import { ExtendedBucket } from '../../@types/extendedTypes.js';
import FileUploadButton from './FileUploadButton.js';
import FilesOverview from './FilesOverview.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoveCollectionModal from './modals/MoveCollectionModal.js';
import ShareCollectionModal from './modals/ShareCollectionModal.js';
import StorageCapacity from './StorageCapacity.js';
import { captureException } from '@sentry/react';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { faFolder } from '@fortawesome/pro-light-svg-icons';
import { twMerge } from 'tailwind-merge';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useTranslation } from 'react-i18next';

type CollectionViewProps = {
  className?: string;
  selectedCollection: ExtendedBucket;
  onAfterConfirmShareDeletion?: (unsharedBucketId: string) => void;
  onAfterConfirmBucketDeletion?: (deletedBucketId: string) => void;
};

function CollectionView({
  className,
  selectedCollection,
  onAfterConfirmShareDeletion,
  onAfterConfirmBucketDeletion,
}: CollectionViewProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const userInfo = useBoundStore((state) => state.userInfo);
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const extendedBuckets = useBoundStore((state) => state.extendedBuckets);
  const updateExtendedBuckets = useBoundStore(
    (state) => state.updateExtendedBuckets
  );

  const { showToast } = useToastManagerHook();

  const [files, setFiles] = useState<
    (FileObjectWithStrategy | TempFileObject)[]
  >([]);
  const [editCollectionModalOpen, setEditCollectionModalOpen] = useState(false);
  const [shareCollectionModalOpen, setShareCollectionModalOpen] =
    useState(false);
  const [deleteShareModalOpen, setDeleteShareModalOpen] = useState(false);
  const [moveCollectionModalOpen, setMoveCollectionModalOpen] = useState(false);

  const [usedStorageMb, setUsedStorageMb] = useState<number>(0);
  const [totalStorage, setTotalStorage] = useState<number>(0);

  async function updateUsedStorage() {
    const { data: used_file_storage, error } = await supabase
      .from('used_file_storage')
      .select('total_size_mb, total_size_sharepoint_mb, workspace(total_seats)')
      .eq('workspace_id', workspaceId);

    if (error) {
      captureException(error);
      showToast({
        title: error.message,
        status: 'error',
      });
    }
    setTotalStorage(
      (planStorageLimitsMb.get(workspaceLicenseType ?? undefined) || 0) *
        (used_file_storage?.[0]?.workspace?.total_seats ?? 1)
    );
    setUsedStorageMb(
      (used_file_storage?.[0]?.total_size_mb || 0) +
        (used_file_storage?.[0]?.total_size_sharepoint_mb || 0)
    );
  }

  async function fetchFiles(shouldUpdateFiles = false) {
    if (selectedCollection) {
      const { data, error } = await supabase.storage
        .from(selectedCollection.id)
        .list(undefined, { limit: 1000 });
      const { data: fileInfoDocument, error: fileInfoDocumentError } =
        await supabase
          .from('storage_file_view')
          .select()
          .eq('bucket_id', selectedCollection.id);

      const filesWithStrategy: FileObjectWithStrategy[] = [];

      if (data) {
        data?.forEach((file) => {
          const item =
            fileInfoDocument &&
            fileInfoDocument.find((val) => val.id === file.id);

          if (!item) {
            filesWithStrategy.push({
              ...file,
              strategy: null,
              numberOfPages: 0,
              embeddingStatus: 'PENDING',
              embeddingFailReason: null,
            });
          } else {
            filesWithStrategy.push({
              ...file,
              strategy: item.embedding_strategy,
              numberOfPages: item.number_of_pages,
              embeddingStatus: item.embedding_status || 'PENDING',
              embeddingFailReason: item.embedding_fail_reason || null,
            });
          }
        });
      }

      if (
        shouldUpdateFiles ||
        !files?.some(
          (file) => 'status' in file && file.status.type === 'UPLOADING'
        )
      ) {
        /* overwrite files list if all files have been uploaded
        otherwise, it will overwrite and the files that are still being uploaded
        will disappear from the list until they have been uploaded successfully */
        setFiles(filesWithStrategy);
      }

      if (error || fileInfoDocumentError) {
        captureException(error ?? fileInfoDocumentError);
        showToast({
          title: error?.message ?? fileInfoDocumentError?.message,
          status: 'error',
        });
      }
    }
  }

  const handleCollectionDeletion = async () => {
    if (!selectedCollection) return;
    const collectionIdToDelete = selectedCollection.id;
    setEditCollectionModalOpen(false);
    onAfterConfirmBucketDeletion?.(collectionIdToDelete);

    const deleteCollectionRes = await fetchApi(
      supabase,
      '/files',
      '/delete_collection',
      { method: 'POST', collectionId: collectionIdToDelete }
    );

    if (!deleteCollectionRes.success) {
      if (
        deleteCollectionRes.status >= 400 &&
        deleteCollectionRes.status < 500
      ) {
        showToast({ title: t('general.unauthorizedError'), status: 'error' });
      }
      // Don't alert on other error -> we will have to look at db every now and then for buckets that have deleted_at set (bucket_info) but were not deleted
      return;
    }

    showToast({
      title: t('fileManagerPanel.collectionDeleteSuccess'),
      status: 'success',
    });
    updateExtendedBuckets();
  };

  async function getSharedBucketId(userId: string | null) {
    if (!selectedCollection || !userId) return;
    onAfterConfirmShareDeletion?.(selectedCollection.id);
    const { data: share, error } = await supabase
      .from('shared_buckets')
      .select('*')
      .eq('bucket_id', selectedCollection.id)
      .eq('user_id', userId)
      .single();

    if (error || !share) {
      captureException(
        error ??
          'Could not get shared bucket id, no error was returned by supabase'
      );
      showToast({
        title: error.message,
        status: 'error',
      });
      return;
    }

    if (!share.share_id) return;

    return share.share_id;
  }

  function handleError(
    resp: ResponseResult<{
      success: boolean;
    }>
  ) {
    if (resp.success) {
      updateExtendedBuckets();
    } else {
      captureException(resp.error);
      showToast({
        title: resp.error.message,
        status: 'error',
      });
    }
  }

  async function handleShareDeletion() {
    const shareId = await getSharedBucketId(userInfo?.userId ?? null);
    if (!shareId) {
      return;
    }
    const resp = await fetchApi(supabase, '/share', '/unshare_bucket', {
      method: 'POST',
      sharedBucketId: shareId,
      workspaceId,
    });
    handleError(resp);
  }

  useEffect(() => {
    fetchFiles();
  }, []);

  useEffect(() => {
    updateUsedStorage();
  }, [workspaceLicenseType, extendedBuckets]);

  async function handleAccessRightsChange(
    newRight: Database['public']['Enums']['access_type'],
    userId: string | null,
    onSuccess?: () => void
  ) {
    const shareId = await getSharedBucketId(userId);
    if (!shareId) {
      return;
    }
    const resp = await fetchApi(supabase, '/share', '/change_access', {
      method: 'POST',
      accessType: newRight,
      sharedBucketId: shareId,
      workspaceId,
    });
    if (resp.success) {
      onSuccess?.();
    }
    handleError(resp);
  }

  return (
    <div
      className={twMerge(
        'flex flex-col items-center justify-between md:h-screen',
        className
      )}
    >
      <div className={`flex h-full min-h-0 w-full flex-col gap-6`}>
        {files && selectedCollection && (
          <div className="relative flex min-h-0 w-full max-w-[1500px] grow flex-col self-center px-8">
            <ContentHeader
              icon={faFolder}
              iconLabel={t('general.dataCollection')}
              headingLabel={decodeName(selectedCollection.display_name)}
              buttons={[
                <Menu strategy="absolute">
                  <MenuButton
                    isDisabled={selectedCollection.access_type !== 'OWN'}
                    as={IconButton}
                    variant={'outline'}
                    icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                    aria-label="TODO"
                  />
                  <MenuList>
                    <MenuItem
                      className="text-sm"
                      onClick={() => {
                        setMoveCollectionModalOpen(true);
                      }}
                    >
                      {t(
                        'fileManagerPanel.migrateToWorkspace.migrateToWorkspaceButton'
                      )}
                    </MenuItem>
                  </MenuList>
                </Menu>,
                <Tooltip
                  label={
                    selectedCollection.access_type === 'VIEW'
                      ? t('fileManagerPanel.disabledReason')
                      : ''
                  }
                  openDelay={500}
                >
                  <Button
                    className="text-maia-text-dark border-maia-border h-8 font-medium"
                    size={'sm'}
                    variant={'outline'}
                    colorScheme="blue"
                    isDisabled={selectedCollection.access_type === 'VIEW'}
                    onClick={() => setEditCollectionModalOpen(true)}
                  >
                    {t('fileManagerPanel.editCollectionButton')}
                  </Button>
                </Tooltip>,
                <Button
                  className="text-maia-text-dark border-maia-border h-8 font-medium"
                  size={'sm'}
                  variant={'outline'}
                  colorScheme="blue"
                  onClick={() => setShareCollectionModalOpen(true)}
                >
                  {t('shareCollectionModal.shareButton')}
                </Button>,
                <FileUploadButton
                  buttonProps={{ size: 'sm' }}
                  isDisabled={selectedCollection.access_type === 'VIEW'}
                  collectionId={selectedCollection.id}
                  existingFileNames={files.map((file) => file.name)}
                  storageLimitMb={totalStorage}
                  usedStorageMb={usedStorageMb}
                  onFilesStartedUploading={(tempFiles) =>
                    setFiles((prev) => [...prev, ...tempFiles])
                  }
                  onFilesFinishedUploading={() => {
                    const shouldUpdateFiles = true;
                    fetchFiles(shouldUpdateFiles);
                    updateUsedStorage();
                  }}
                />,
              ]}
            />
            <FilesOverview
              files={files}
              selectedCollection={selectedCollection}
              onFileListChanged={() => {
                // TODO that is only possible cause the file is in the root of a bucket
                if (selectedCollection) {
                  fetchFiles();
                  updateUsedStorage();
                }
              }}
            />
          </div>
        )}
      </div>
      <div className="z-[1] w-full border-t border-solid border-gray-200 py-3">
        <StorageCapacity usedMb={usedStorageMb} totalMb={totalStorage} />
      </div>
      <EditCollectionModal
        deleteTooltipLabel={
          selectedCollection?.access_type === 'VIEW'
            ? t('fileManagerPanel.disabledReason')
            : ''
        }
        deleteIsDisabled={selectedCollection?.access_type === 'VIEW'}
        isOpen={editCollectionModalOpen}
        onClose={() => setEditCollectionModalOpen(false)}
        onDelete={handleCollectionDeletion}
        fetchCollections={updateExtendedBuckets}
        selectedCollectionName={selectedCollection?.display_name}
        selectedCollectionId={selectedCollection?.id}
      />
      <DeleteShareModal
        isOpen={deleteShareModalOpen}
        onClose={() => setDeleteShareModalOpen(false)}
        onConfirm={handleShareDeletion}
      />
      <ShareCollectionModal
        onAccessChange={handleAccessRightsChange}
        isOpen={shareCollectionModalOpen}
        onClose={() => setShareCollectionModalOpen(false)}
        selectedCollectionId={selectedCollection.id}
        isPublicForWorkspace={selectedCollection.is_public_for_workspace}
      />
      <MoveCollectionModal
        collectionId={selectedCollection.id}
        collectionName={decodeName(selectedCollection.display_name)}
        isOpen={moveCollectionModalOpen}
        onClose={() => setMoveCollectionModalOpen(false)}
      />
    </div>
  );
}

export default CollectionView;
