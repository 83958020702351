import { Checkbox, IconButton, Skeleton, Tooltip } from '@chakra-ui/react';
import {
  Database,
  SharepointFileSelectionMap,
  SupportedFileType,
  supportedFileTypes,
  zeros,
} from 'common-ts';
import {
  FolderBreadCrumbs,
  isFileSelectable,
  stringPathFromBreadCrumbs,
} from './utils';
import {
  faAngleRight,
  faArrowLeft,
  faFile,
  faRotateRight,
} from '@fortawesome/pro-regular-svg-icons';

import FileIcon from '../../../components/FileIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItem from '../../../components/ListItem';
import SharepointBreadCrumbs from './SharepointBreadCrumbs';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { useBoundStore } from '../../../store/useBoundStore';

type SharepointSiteFoldersAndFilesProps = {
  className?: string;
  files: Database['public']['Views']['sharepoint_file_view']['Row'][];
  folders: Database['public']['Views']['sharepoint_folder_view']['Row'][];
  loading: boolean;
  breadCrumbs: FolderBreadCrumbs;
  selectedFileMap: SharepointFileSelectionMap[string]['folders'];
  currentSiteOverallFileCount: number;
  onBackClick: () => void;
  onFolderClick: (params: { folderId: string; name: string }) => void;
  onRefreshClick: () => void;
  onSelectAllClick: () => void;
  onDeselectAllClick: () => void;
  onFileCheckboxClick: (fileId: string) => void;
  onFolderCheckboxClick: (folderId: string) => void;
  onSelectFolderInCrumbs: (index: number) => void;
  // onBackFromRootFolderClick: () => void;
};

/**
 * Component for traversing and selecting folders and files in a sharepoint site.
 */
function SharepointSiteFoldersAndFiles({
  className,
  files,
  folders,
  loading,
  breadCrumbs,
  selectedFileMap,
  currentSiteOverallFileCount,
  onBackClick,
  onFolderClick,
  onRefreshClick,
  onSelectAllClick,
  onDeselectAllClick,
  onFileCheckboxClick,
  onFolderCheckboxClick,
  onSelectFolderInCrumbs,
  // onBackFromRootFolderClick,
}: SharepointSiteFoldersAndFilesProps) {
  const { t } = useTranslation();
  const csv = useBoundStore((state) => state.featureFlags?.csv);

  return (
    <div className={twMerge('flex h-full flex-col gap-2 px-4 py-2', className)}>
      {/* ---------------------------------------------------------------- Head ---------------------------------------------------------------- */}
      <div className="flex items-center justify-between">
        <div className="flex items-baseline gap-2">
          <div className="text-chakra-gray-500">{`${currentSiteOverallFileCount} ${t(
            'fileManagerPanel.totalFiles'
          )}`}</div>
          <IconButton
            aria-label="Refresh file list"
            variant={'ghost'}
            size={'xs'}
            icon={
              <FontAwesomeIcon
                icon={faRotateRight}
                className="text-chakra-gray-600 text-xs"
              />
            }
            onClick={() => {
              onRefreshClick();
            }}
          />
        </div>
        {files.length || folders.length ? (
          <div className="flex items-center gap-2">
            <div
              className="text-maia-accent cursor-pointer select-none text-xs"
              onClick={() => {
                onSelectAllClick();
              }}
            >
              {t('general.selectAll')}
            </div>
            <div
              className="text-maia-support-red cursor-pointer select-none text-xs"
              onClick={() => {
                onDeselectAllClick();
              }}
            >
              {t('general.deselectAll')}
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex items-center gap-2">
        <IconButton
          className={`${breadCrumbs.length === 0 ? 'md:hidden' : ''}`}
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          aria-label="back"
          variant={'icon-only'}
          onClick={() => {
            onBackClick();
          }}
        />
        <SharepointBreadCrumbs
          className="flex-grow"
          folders={breadCrumbs}
          onFolderSelect={onSelectFolderInCrumbs}
        />
        <div
          className={twMerge(
            `text-chakra-gray-400 mr-[30px] flex items-center gap-1`,
            breadCrumbs.length !== 0 ? 'mr-[42px]' : ''
          )}
        >
          <FontAwesomeIcon icon={faFolder} />|
          <FontAwesomeIcon icon={faFile} />
        </div>
      </div>
      <div className="flex flex-col gap-2 overflow-y-auto">
        {/* ------------------------------------------------ Loading ------------------------------------------------ */}
        {loading ? (
          zeros(5).map((_, index) => {
            return (
              <Skeleton key={index}>
                <ListItem selected={false}>Loading</ListItem>
              </Skeleton>
            );
          })
        ) : (
          <>
            {/* ---------------------------------------------------------------- Folders ---------------------------------------------------------------- */}
            {folders.map((folder) => {
              const checkedStatus =
                selectedFileMap[
                  `${stringPathFromBreadCrumbs(breadCrumbs).folderPath}_${folder.id}`
                ]?.selected;

              return (
                <ListItem
                  key={folder.id}
                  selected={false}
                  className="flex h-10 flex-shrink-0 cursor-pointer items-center gap-2 px-4"
                >
                  <Checkbox
                    colorScheme="maia-purple"
                    className="hover:bg-maia-purple-50 rounded"
                    isChecked={checkedStatus ?? false}
                    isIndeterminate={checkedStatus === null}
                    // It is very important that clicking on this Checkbox does not trigger folder navigation (at least not before handling the onChange)
                    // Otherwise, the breadcrumbs will not match anymore once the onChange event is handled
                    // Sadly, stopPropagation on this Checkboxes onClick event does not have the desired effect, so we can't use the ListItems onClick event for folder navigation.
                    onChange={() => {
                      onFolderCheckboxClick(folder.id!);
                    }}
                  />
                  {/* The div hierarchy is a bit awkward here in order to separate the checkbox from the rest of the Listitem's content because of the onClick problem mentioned above */}
                  <div
                    className="flex flex-grow items-center justify-between"
                    onClick={() => {
                      onFolderClick({
                        folderId: folder.id!,
                        name: folder.name!,
                      });
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <FontAwesomeIcon
                        icon={faFolder}
                        className="text-chakra-gray-400 text-base"
                      />
                      <Tooltip label={folder.name} openDelay={150}>
                        <span className="truncate text-sm">{folder.name}</span>
                      </Tooltip>
                    </div>
                    <div className="text-chakra-gray-500 flex items-center gap-1">
                      <div>{folder.child_folder_count}</div>|
                      <div>{folder.file_count}</div>
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="text-xs"
                      />
                    </div>
                  </div>
                </ListItem>
              );
            })}
            {/* ---------------------------------------------------------------- Files ---------------------------------------------------------------- */}
            {files.map((file) => {
              const checkedStatus =
                selectedFileMap[
                  stringPathFromBreadCrumbs(breadCrumbs).folderPath
                ]!.files[file.id!];

              const fileTypeSupported = supportedFileTypes.includes(
                (file.mime_type ?? '') as SupportedFileType
              );

              return (
                <ListItem
                  key={file.id}
                  selected={false}
                  className="flex h-10 flex-shrink-0 cursor-pointer items-center gap-2 px-4"
                >
                  <Checkbox
                    isDisabled={!isFileSelectable(file, csv)}
                    colorScheme="maia-purple"
                    className="hover:bg-maia-purple-50 rounded"
                    isChecked={checkedStatus}
                    onChange={() => {
                      onFileCheckboxClick(file.id!);
                    }}
                  >
                    <Tooltip
                      isDisabled={file.embedding_status === 'FINISHED'}
                      label={
                        !fileTypeSupported
                          ? t('chat.fileSelector.fileTypeNotSupportedTooltip')
                          : file.embedding_status === 'FAILED'
                            ? t('chat.fileSelector.fileFailedTooltip')
                            : t('chat.fileSelector.fileNotProcessedTooltip')
                      }
                    >
                      <div className="flex h-10 w-72 items-center gap-2 pl-2">
                        <FileIcon
                          name={file.name!}
                          status={file.embedding_status!}
                          className="text-chakra-gray-400 text-base"
                        />
                        <Tooltip
                          label={file.name}
                          openDelay={150}
                          placement="top"
                        >
                          <span className="truncate text-sm">{file.name}</span>
                        </Tooltip>
                      </div>
                    </Tooltip>
                  </Checkbox>
                </ListItem>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default SharepointSiteFoldersAndFiles;
